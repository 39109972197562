import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const BodyContainer = styled.div`
         
         }`;

export const Lines = styled.div`
  display: flex;
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  max-width: 1400px;
  margin: 0 auto;
  border-left: 1px solid #e7e7e8;
  border-right: 1px solid #e7e7e8;
  justify-content: space-evenly;
  align-items: stretch;
  & > div {
    width: 1px;
    background: #e7e7e8;
  }
`;
