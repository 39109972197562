import styled from 'styled-components';
// import { mainColor } from 'constants/theme';

export const Container = styled.nav`
  display: flex;
  align-items: stretch;

  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      text-transform: capitalize;
      position: relative;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      position: relative;
      a,
      .parent-link {
        display: inline-block;
        color: #55504b;
        padding: 16px 8px;
        transition: all 0.2s ease;
        display: flex;
        font-size: 1.6rem;
        align-items: center;
        font-family: 'Muli';
        font-weight: 600;
        &:after {
          position: absolute;
          background: green;
          background: ${props => props.theme.primaryColor};
          transition: all 0.2s ease;
          width: 0%;
          content: '';
          bottom: 0;
          height: 4px;
          left: 25%;
        }
        &.active {
          &:after {
            width: 50%;
          }
        }
      }

      & + li {
        margin-left: 1.6rem;
      }
      &.parent:hover {
        & > ul {
          display: flex;
        }
      }
      ul {
        position: relative;
        &.sub-menu {
          display: none;
          position: absolute;
          background: #fff;
          padding: 16px 8px;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
          flex-direction: column;
          z-index: 9999;
          top: 50px;
          right: 0;
          li {
            margin: 0;
          }
        }
      }
    }
  }
`;
